.content-inner {
  text-decoration: none;
  font-size: 1em;
  outline: none;
  margin: 0;
  padding-top: 0;
  padding-bottom: 15px;
  padding-right: 0;
  padding-left: 0;
}
