html,
body,
#root {
  height: 100%;
}
.App {
  text-align: center;
  background-image: url('./images/backgrounds/Webbgd.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: scroll;
}
.App::-webkit-scrollbar {
  display: none;
}
.App,
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  margin: 0px;
  color: #ffffff;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.nav-item a:hover, .nav-link:hover{
  color:#ffa200;
}
.dropdown-item:focus, .dropdown-item:hover{
  background-color:#282c34;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav {
  color:white;
}
.navbar-light .navbar-nav .nav-link{
  color:white;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
  color:#ffa200
}
blockquote{
background-image: url('./images/quote-up.png');
    background-repeat: no-repeat;
    background-position: left top;
    padding-left: 70px;
}
.img-left {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}
.img-right {
  float: right;
  margin-bottom: 20px;
  margin-left: 20px;
  width: 150px;
  height: 397px;
}
.rowWithImg {
  display: inherit !important;
  padding-bottom: 100px !important;
}
ul.tick li {
  background-image: url(./images/tick.png);
  background-repeat: no-repeat;
  background-position: left 3px;
  padding-top: 2px;
  padding-left: 25px;
  list-style-image: none;
  list-style-type: none;
}
.map-responsive {
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
}
.uppercase {
  text-transform: uppercase;
}
.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
#extCarousel ._-LJ2W {
  display: none;
}
#extCarouselContainer {
  width: 80%;
}
ul.arrow li {
  background-image: url(./images/arrow-list.png);
  background-repeat: no-repeat;
  background-position: left 3px;
  padding-top: 2px;
  padding-left: 25px;
  list-style-image: none;
  list-style-type: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
a:link,
a:visited {
  color: #ffa200;
}

/*Custom CSS*/
/* * {
  text-decoration: none;
  font-size: 1em;
  outline: none;
  margin: 0;
}

#nojs-background img {
  min-height: 100%;
  min-width: 1050px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}


#header-inner {
  width: 940px; 
  width: 100%;
  height: 140px;
  padding: 0;
  margin: 0 auto;
 position: relative;
}

#nav-wrap {
  float: right;
  padding: 0;
  background-color: #171717;
  border: 1px solid #000;
  margin-top: 43px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  font-family: 'Lucida Sans Unicode', 'DejaVu Sans', Verdana, sans-serif;
  z-index: 9999;
}
.nav-wrap-inner {
  float: right;
  padding: 0;
  margin: 0 auto;
  border: 1px solid #252525;
  width: 600px;
}
.nav-nav {
  margin: 0px;
  padding: 0px;

  display: block;
}
.nav-item {
  display: inline;
  z-index: 9999;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

.nav-item a,
.nav-link {
  color: #f7f7f7 !important;
  background-image: url(./images/nav-a-bg.png);
}


.content-inner {
  width: 940px;
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 0;
  margin-left: auto;
  padding: 0;
  line-height: 150%;
} */

/*****************************************************
5.  HEADINGS
******************************************************/

#nobg-dropdown {
  background-image: none !important;
}
.left-note-block {
  text-align: left;
  background-image: url(./images/opacity-60-rep.png);
  background-repeat: repeat;
  padding: 20px;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}
* {
  text-decoration: none !important;
  font-size: 1em;
  outline: none;
  margin: 0;
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}

.homePadding{
  padding-bottom:15px;
}
h1 {
  font-size: 29px;
  color: #fff;
  font-weight: normal;
  margin-top: 3px;
  margin-bottom: 3px;
  display: block;
}
h2 {
  font-size: 17px;
  color: #ffa200;
  font-weight: normal;
  margin-bottom: 8px;
}
h3 {
  font-size: 17px;
  color: #fff;
  font-weight: normal;
  margin-bottom: 6px;
  margin-top: 3px;
}
h4 {
  font-size: 13px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 3px;
}
#flickrembed{
  height:100%;
}

#tpcl p {
  font-size: 12px;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.plain-black {
  text-align: left;
}
.plain-black ul li {
  list-style-type: none;
}
/*Vimeo Playlist*/
#player_playlist{
  background-color: #000;
  color: #fff;
  font: normal 12px/14px helvetica,arial,sans-serif;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
#player_playlist ul{
list-style: none;
    margin: 0;
    padding: 0 9px 0 0;
}

#player_playlist li{
  list-style: none;
    clear: both;
    display: block;
    height: 60px;
    line-height: 16px;
    margin: 0 0 2px;
    padding: 0;
    overflow: hidden;
}
#player_playlist .shade {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#player_playlist span {
  color: #fff;
  display: block;
}
#player_playlist{
    max-height:300px;
    overflow: auto;
    text-decoration: none;
    font-size: 1em;
    outline: none;
    margin: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 15px;
    padding-left: 0;
}
#player_playlist li > a {
  display: block;
  position: relative;
  height: 60px;
  text-decoration: none;
}
#player_playlist .border {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  border-right: rgba(255, 255, 255, .1) 1px solid;
  width: 80px;
  height: 60px;
}
#player_playlist img {
  border: none;
  float: left;
  width: 80px;
  height: 60px;
  margin: 0 7px 0 0;
}
#player_playlist .title {
  font-weight: bold;
  padding-top: 5px;
  font-size: 14px;
}
#player_playlist .byline {
  color: #9DA2A8;
}
#player_playlist .desc {
  color: #71767A;
}
#player_playlist .duration {
  position: absolute;
  top: 3px;
  right: 7px;
}
.content-Contact {
  margin: auto;
  width: 30%;
  padding: 10px;
}
#header-outer {
  margin: 0 auto;
  color: #fff;
  background-image: url(./images/header.png);
  background-repeat: repeat-x;
  background-position: left 15px;
  border-top-width: 5px;
  border-top-style: solid;
  border-top-color: #000;
}
#header-inner {
  width: 940px;
  width: 100%;
  height: 140px;
  padding: 0;
  margin: 0 auto;
  position: relative;
}
.nav-item a,
.nav-link {
  color: #f7f7f7;
}
.navbar-nav,
.dropdown-menu {
  float: right;
  padding: 0;
  background-color: #171717;
  border: 1px solid #000;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  font-family: 'Lucida Sans Unicode', 'DejaVu Sans', Verdana, sans-serif;
  z-index: 9999;
}

.transparent-header {
  text-align: left;
  background-image: url(./images/opacity-60-rep.png);
  background-repeat: repeat;
  padding: 20px;
  height: 55px;
  border-top-left-radius: 8px;
  -moz-border-radius-topleft: 8px;
  -webkit-border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -webkit-border-top-right-radius: 8px;
}

  
  
  
  
  
@media (max-width: 1024px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .navbar-nav,
  .dropdown-menu{
    width:100%;
    font-size: large;
  }
  .navbar-light .navbar-toggler {
    
    border-color: rgba(255, 255, 255, 0.692) !important;
}
.navbar-collapse {
  z-index:1;
}
.navbar-light .navbar-toggler-icon{
  background-image: url(
"data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.EAP {
  height: 600px;
}
}
@media (min-width: 320px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  /* img {
    width: 100%;
    height: auto;
  } */
  .EAP {
    height: 300px;
  }
  .navbar-brand{
    margin-left:20px;
  }
  .navbar-toggler{
    margin-right:20px;
  }
  .full-width-transparent {
    text-align: left;
    height: 350px;
    height: auto;
    min-height: 350px;
    background-image: url(./images/opacity-60-rep.png);
    background-repeat: repeat;
    padding: 20px;
    border-radius: 8px;
    border-top-left-radius: 8px;
    -moz-border-radius-topleft: 8px;
    -webkit-border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    -moz-border-radius-topright: 8px;
    -webkit-border-top-right-radius: 8px;
  }
  .bottom-breaker {
    padding-top: 5px;
    padding-bottom: 35px;
    margin-bottom: 10px;
    background-image: url(./images/section-breaker-white.png);
    background-repeat: repeat-x;
    background-position: left bottom;
  }
  /* .content-inner {
    margin: auto;
    width: 95%;
    padding: 20px;
  } */
  .content-Contact {
    margin: auto;
    width: 80%;
    padding: 10px;
  }

  #footer {
    clear: both;
    position: fixed;
    bottom: 0 !important;
    margin-left: -498px;
    height: 27px;
    overflow: visible;
    z-index: 1200;
    width: 996px;
    left: 50%;
  }
  .footer-inside {
    width: 996px;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    color: #bbbbbb;
    font-size: 11px;
    line-height: 11px;
    background-image: url(./images/footer-inside.png);
    background-repeat: no-repeat;
    background-position: left top;
  }
  .foot-left-col {
    float: left;
    width: 505px;
    height: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 0px;
    padding-left: 48px;
    line-height: 14px;
    font-style: italic;
  }

  .foot-right-col {
    float: right;
    padding-top: 2px;
    padding-right: 48px;
    position: relative;
  }

  ul.footer-menu {
    margin: 0px;
    padding: 0px;
    float: right;
  }
  ul.footer-menu li {
    display: inline;
    margin: 0px;
    padding: 0px;
  }
  ul.footer-menu li a {
    list-style-image: none;
    list-style-type: none;
    float: left;
    line-height: 14px;
    background-color: #171717;
    margin-right: 1px;
    padding-top: 6px;
    padding-right: 8px;
    padding-bottom: 6px;
    padding-left: 10px;
    color: #fff;
    height: 14px;
    font-size: 9px;
    text-transform: uppercase;
    text-shadow: #000 -1px -1px 0px;
  }
  ul.footer-menu li a:hover {
    text-decoration: none;
    color: #ffa200;
    background-color: #252525;
    text-shadow: #000 1px 1px 0px;
  }
  .footer-menu .up-ico {
    background-image: url(./images/sml-up-arrow.png);
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
    display: block;
  }
  .footer-menu .down-ico {
    background-image: url(./images/sml-down-arrow.png);
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
    display: block;
  }
  .footer-pop-out-box {
    position: absolute;
    width: 240px;
    bottom: 28px;
    right: 45px;
    background-image: url(./images/opacity-80-rep.png);
    background-repeat: repeat;
    display: none;
    -webkit-border-top-left-radius: 12px;
    -webkit-border-top-right-radius: 12px;
    -moz-border-radius-topleft: 12px;
    -moz-border-radius-topright: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
}
@media (min-width: 481px) {
  .left-note-block {
    width: 400px;
  }
  .plain-black {
    background: #0d0d0c;
    padding: 20px;
    -webkit-box-shadow: inset 0px 1px 3px #080707;
    -moz-box-shadow: inset 0px 1px 3px #080707;
    box-shadow: inset 0px 1px 3px #080707;
  }
  .EAP {
    height: 400px;
  }
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  .left-note-block {
    width: 100%;
  }
  .EAP {
    height: 600px;
  }
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}


@media (min-width: 1025px) {
  /* hi-res laptops and desktops */
  /* img {
    width: 70%;
    height: auto;
  } */
  .EAP {
    height: 600px;
  }
  .custNavItem,
  .dropdown-toggle {
    z-index: 9999;
    line-height: 1.3em;
    color: #f7f7f7;
    float: left;
    height: 3em;
    text-shadow: #000 -1px -1px 0px;
    background-image: url(./images/nav-a-bg.png);
    background-repeat: no-repeat;
    background-position: right top;
    padding-top: 11px;
    padding-right: 30px !important;
    padding-bottom: 11px !important;
    padding-left: 20px !important;
    font-size: 13px;
  }
  .content-inner {
    margin: auto;
    width: 60%;
    padding: 20px;
  }

}
@media (min-width: 648px) {
  /* img {
    width: 80%;
    height: auto;
  } */
  .homeContent{
    margin-left:5% !important;
  }
  .content-inner {
    margin: auto;
    width: 80%;
    padding: 20px;
  }
  .content-Contact {
    margin: auto;
    width: 50%;
    padding: 10px;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
